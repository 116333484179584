<template>
  <div class="course-card">
    <b-row>
      <b-col class="cardContent pr-2" v-for="(item, index) in list" :key="index" md="6" lg="4">
        <router-link :to="{ path: '/course-detail', query: { id: item.id, type: 3 } }">
          <iq-card body-class="profile-page p-0" class="iqCard">
            <template v-slot:body>
              <div class="profile-header-image h-100">
                <!-- <div style="height: 156.5px; overflow: hidden" class="cover-container">
                  <img :src="item.image" alt="profile-bg" class="rounded img-fluid h-100 w-100" />
                </div> -->
                <el-image style="
                          width: 100%;
                          height: 156.5px;
                          border-top-left-radius: 5px;
                          border-top-right-radius: 5px;
                        " fit="cover" :src="item.image">
                </el-image>
                <div class="userDetail px-3 pt-2">
                  <div class="introduce">
                    <h4 class="title" :title="item.title">{{ item.title }}</h4>
                    <p class="desc my-2" style="color: #999">
                      {{ item.time }}
                    </p>
                    <!-- 小头像 -->
                    <div v-if="item.avatar.length > 0" class="d-flex align-items-center">
                      <div class="iq-media-group d-flex align-items-center">
                        <a v-for="(v, i) in item.avatar.slice(0, 3)" :key="i" href="#" class="iq-media">
                          <img class="img-fluid rounded-circle" :src="v.avatar" />
                        </a>
                        <div class="textDesc ml-2">
                          <span style="color: #999"> {{ item.desc }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </iq-card>
        </router-link>
      </b-col>
    </b-row>
    <div class="d-flex justify-content-end">
      <Pagination :total="total" @changeCurrentPage="changeCurrentPage" />
    </div>
  </div>
</template>
<script>
  import Pagination from "@/components/ComComponents/Pagination";
  export default {
    components: { Pagination },
    data() {
      return {
        total: 12,
      };
    },
    props: ["list"],
    methods: {
      changeCurrentPage(e) {
        this.getMeetingList(e);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .introduce {
    /* height: 120px; */
    padding-bottom: 5px;
    display: flex !important;
    flex-flow: column !important;
    /* justify-content: space-between !important; */

    // 小头像
    .iq-media {
      width: 24px;
      height: 24px;
      margin-left: -12px !important;

      img {
        width: 100%;
      }
    }

    .iq-media-group .iq-media:first-child {
      margin-left: 0 !important;
    }
  }

  .cardContent {
    router-link {
      color: inherit;
    }

    .iqCard {
      height: 281px;
      /* width: 313px; */

      .title {
        color: #333;
        font-size: 16px;
        margin-bottom: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis
      }

      .desc {
        font-size: 14px;
      }
    }
  }
</style>