<template>
  <div class="Course">
    <!-- 页面 header 通屏大图 -->
    <FullBanner :bannerURL="$route.meta.img" :moduleName="$route.meta.name">
      <template v-slot:customFill>
        <iq-card class="iq-card">
          <section class="container">
            <div class="content">
              <el-row class="py-3">
                <el-col :span="24">
                  <el-input clearable @clear="courseList" @change="courseList" class="w-100" placeholder="Search Course"
                    v-model="seach"></el-input>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="4" class="mr-4">
                  <el-date-picker style="width: 100%" clearable v-model="date" @change="courseList" type="date"
                    :placeholder="$t('Events.c0f@date')" value-format="yyyy-MM-dd">
                  </el-date-picker>
                </el-col>
                <el-col :span="16">
                  <OrganazationSel @getOrganizationParams="getOrganizationParams" />
                </el-col>
              </el-row>
            </div>
            <div class="tab-btn-my">
              <tab-nav :tabs="true" id="myTab-two" class="tabsWidthScroll">
                <tab-nav-items :active="active == 'prerecording'" href="#prerecording" title="Explore"
                  @click.native="changeCurrentPage('0')" />
                <tab-nav-items :active="active == 'living'" href="#living" title="My Course"
                  @click.native="changeCurrentPage('1')" />
              </tab-nav>
              <!-- 是否是我的课程 -->
              <div @click="$router.push('/my-certificate')" class="toMyCourse">
                <img style="height: 20px" src="../../assets/images/course/certificate.png" alt="" />
                <span>My Certificate</span>
              </div>
            </div>
          </section>
        </iq-card>
      </template>
    </FullBanner>

    <tab-content>
      <tab-content-item :active="active == 'prerecording'" aria-labelled-by="prerecording-tab-two">
        <div class="container">
          <b-row v-if="loading">
            <b-col v-for="(item, index) in 12" :key="index" md="6" lg="4">
              <el-skeleton animated>
                <template slot="template">
                  <iq-card body-class="p-0" style="border-radius: 5px;overflow: hidden;">
                    <el-skeleton-item variant="image" style="width: 100%; height: 160px;" />
                    <div style="padding: 22px 10px 20px;" class="d-flex flex-column">
                      <el-skeleton-item variant="text" style="width: 60%;margin-bottom: 20px;" />
                      <el-skeleton-item variant="text" style="width: 40%;margin-bottom: 20px;" />
                      <el-skeleton-item variant="text" style="width: 60%" />
                    </div>
                  </iq-card>
                </template>
              </el-skeleton>
            </b-col>
          </b-row>
        </div>
        <div v-if="list.length&&!loading" class="container">
          <Prerecording :list="list" />
        </div>
        <div v-if="!list.length" class="d-flex justify-content-center" style="background:#fff;margin-top: 60px">
          <img style="width:250px;" src="../../assets/images/group/empty1.png" alt="" v-if="!loading" />
        </div>
      </tab-content-item>
      <tab-content-item :active="active == 'living'" aria-labelled-by="prerecording-tab-two">
        <div v-if="list.length" class="container">
          <Prerecording :list="list" />
        </div>
        <div v-if="!list.length" class="d-flex justify-content-center" style="background:#fff;margin-top: 60px">
          <img style="width:250px;" src="../../assets/images/group/empty1.png" alt="" v-if="!loading" />
        </div>
      </tab-content-item>
    </tab-content>
    <!-- <div class="container">
      <Prerecording :list="list" />
    </div> -->
    <div class="d-flex justify-content-end container mt-1">
      <el-pagination background layout="prev, pager, next" :page-size="size" @current-change="handleCurrentChange"
        :current-page.sync="page" :total="total" hide-on-single-page>
      </el-pagination>
    </div>
  </div>
</template>
<script>
  import FullBanner from '@/components/ComComponents/FullBanner'
  import Prerecording from './components/Prerecording'
  import OrganazationSel from '@/components/ComComponents/OrganazationSel'

  export default {
    name: 'Course',
    mounted() { },
    components: { FullBanner, Prerecording, OrganazationSel },
    data() {
      return {
        total: 0,
        seach: '',
        organization_id: '',
        page: 1,
        size: 12,
        date: '',
        active: 'prerecording',
        list: [],
        type: 0,
        loading: true
      }
    },
    created() {
      this.courseList()
    },

    methods: {
      changeCurrentPage(type) {
        this.type = type
        this.seach = ''
        this.organization_id = ''
        this.date = ''
        this.page = 1
        this.courseList()
      },
      async courseList() {
        this.loading = true
        let res = await this.$http.courseList({
          type: this.type,
          keyword: this.seach,
          date: this.date,
          organization_id: this.organization_id,
          per_page: this.size,
          page: this.page
        })
        if (res.status == 200) {
          this.list = res.data.data
          this.total = res.data.total
        }
        setTimeout(() => {
          this.loading = false
        }, 0);
      },
      getOrganizationParams(data) {
        let OrganData = JSON.parse(JSON.stringify(data))
        // console.log(OrganData);
        let level = OrganData.level.id
        switch (level) {
          case 0: {
            // jci
            this.organization_id = OrganData.jci.id
            this.courseList()
            break
          }
          case 4: {
            // area
            this.organization_id = OrganData.area.id
            this.courseList()
            break
          }
          case 2: {
            // nation
            this.organization_id = OrganData.national.id
            this.courseList()
            break
          }
          case 3: {
            // region
            this.organization_id = OrganData.regional.id
            this.courseList()
            break
          }
          case 1: {
            // local
            this.organization_id = OrganData.local.id
            this.courseList()
            break
          }
        }
      },
      // 分页
      handleCurrentChange(page) {
        this.page = page
        this.courseList()
      }
    }
  }
</script>
<style lang="scss" scoped>
  .Course {
    min-height: 100vh;

    .content {

      // padding-top: 28px;
      ::v-deep .el-input__inner {
        height: 45px;
        border-radius: 11px;
      }
    }
  }

  .tab-btn-my {
    margin-top: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    #myTab-two {
      margin-bottom: 0;
      position: relative;
    }

    .toMyCourse {
      width: 150px;
      height: 35px;
      text-align: center;
      line-height: 35px;
      position: absolute;
      right: 250px;
      color: #50b5ff;
      cursor: pointer;
    }
  }
</style>